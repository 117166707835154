// ====================
// Homepage News script
// ====================

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const newsList = document.querySelectorAll('.HomepageNews-list');

  for (const newsItem of newsList) {
    const sliderContainer = newsItem.querySelector('.swiper-container');

    const websitesListSlider = new Swiper(sliderContainer, {
      slidesPerView: 1.3,
      spaceBetween: 30,
      watchOverflow: true,
      breakpoints: {
        1100: {
          slidesPerView: 3
        },
        800: {
          slidesPerView: 2.3
        },
        600: {
          slidesPerView: 1.8
        }
      }
    });

    const newsText = newsItem.querySelectorAll('.HomepageNews-listItem .NewsItem-text');

    for (const text of newsText) {
      const newsImage = text.previousElementSibling;
      text.style.height = 'calc(100% - ' + newsImage.offsetHeight + 'px)';
    }

    window.addEventListener('resize', function() {
      for (const text of newsText) {
        const newsImage = text.previousElementSibling;
        text.style.height = 'calc(100% - ' + newsImage.offsetHeight + 'px)';
      }
    });
  }
}
