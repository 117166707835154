// ===============
// Homepage Slider
// ===============

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.HomepageSlider');

  for (const slider of sliders) {
    const sliderContainer = slider.querySelector('.HomepageSlider-container');

    const newSlider = new Swiper(sliderContainer, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      watchOverflow: true,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: '.HomepageSlider-buttonNext',
        prevEl: '.HomepageSlider-buttonPrev',
      },
      pagination: {
        el: '.HomepageSlider-fraction',
        type: 'fraction'
      }
    });

    // Stop autoplay on mouse enter
    sliderContainer.addEventListener('mouseenter', function() {
      newSlider.autoplay.stop();
    });
  }
}