// Enable SVG <use xlink:href> support in IE11
import svg4everybody from "svg4everybody";
svg4everybody();

// Import each icon that we want to include in our SVG sprite
// (built by Webpack + svg-sprite-loader)
import "./arrow-large.svg";
import "./arrow-short.svg";
import "./audio.svg";
import "./cross.svg";
import "./download.svg";
import "./email.svg";
import "./facebook.svg";
import "./heart.svg";
import "./heart-empty.svg";
import "./instagram.svg";
import "./logo.svg";
import "./menu.svg";
import "./play-video.svg";
import "./search.svg";
import "./twitter.svg";
import "./youtube.svg";
import "./trash.svg";
