// =======================
// Search Facets Thematics
// =======================

import $ from "jquery";

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function normalize(string) {
    return string
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[\-\.]/g, "")
        .toLowerCase();
}

function init() {
    var $listItem = $('#SearchFacets-authorList li');
    $('#SearchFacets-authorSearchInput').keyup(function () {
        var text = normalize($(this).val());
        $listItem.each(function () {
           if (normalize(this.dataset.value).indexOf(text) != -1) {
               this.style.display = 'block';
           } else {
               this.style.display = 'none';
           }
        });
    });
}

