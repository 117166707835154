import Cookies from 'js-cookie'

const cookieName = 'prtk'
const helper = {
    password_request: {
        selector: 'pro_area_password_request',
        route: {
            name: 'pro_area_request_password',
            method: 'POST'
        }
    },
    authenticate: {
        selector: 'pro_area_authenticate',
        route: {
            name: 'pro_area_authenticate',
            method: 'POST'
        }
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function http({name, method}, body) {
    return new Promise(function(resolve, reject) {
        fetch(Routing.generate(name), {
            method: method,
            body: body
        })
            .then(response => {
                response.json()
                    .then(json => {
                        resolve({
                            headers: response.headers,
                            json: json
                        })
                    })
                    .catch(error => reject(error))
            })
            .catch(error => reject(error))
    })
}

function initPasswordRequestForm() {
    const form = document.querySelector(`[name="${helper.password_request.selector}"]`)
    if (form === null || typeof form === 'undefined') { return }

    form.querySelector('.winniethepoe').style.display = 'none'

    form.addEventListener('submit', function(e) {
        e.preventDefault()

        const errorMsg = form.querySelectorAll('.passwd_error')
        errorMsg.forEach(msg => {
            msg.remove()
        })

        http(helper.password_request.route, new FormData(form))
            .then(({json}) => {
                switch (json.status) {
                    case 'ok':
                        const node = document.createElement('div')
                        node.textContent = 'Votre demande a bien été transmise au service de presse, nous vous répondrons dans les meilleurs délais.\nÀ très bientôt.'
                        document.querySelector(`[name="${helper.password_request.selector}"]`)
                            .insertAdjacentElement('beforeend', node)
                        break;
                    case 'error':
                        Object.entries(json.errors).forEach(([key, error]) => {
                            const node = document.createElement('div')
                            node.classList.add(['passwd_error'])
                            node.textContent = error
                            let selector, position
                            if (key === 'pro_area_password_request') {
                                selector = `[name="${helper.password_request.selector}"]`
                                position = 'afterbegin'
                            } else if (key === 'first' || key === 'second') {
                                selector = `[name="${helper.password_request.selector}[emailAddress][${key}]"]`
                                position = 'beforebegin'
                            } else {
                                selector = `[name="${helper.password_request.selector}[${key}]"]`
                                position = 'afterend'
                            }
                            document.querySelector(selector)
                                .insertAdjacentElement(position, node)
                        })
                        break
                }
            })
            .catch(error => console.log(error))
    })
}

function initPasswordForm() {
    const form = document.querySelector(`[name="${helper.authenticate.selector}"]`)
    if (form === null || typeof form === 'undefined') { return }

    form.addEventListener('submit', function(e) {
        e.preventDefault()

        const errorMsg = form.querySelector('.passwd_error')
        if (errorMsg) errorMsg.remove()

        http(helper.authenticate.route, new FormData(form))
            .then(({headers, json}) => {
                switch (json.status) {
                    case 'ok':
                        Cookies.set(cookieName, headers.get(cookieName), {
                            sameSite: 'strict'
                        })
                        if (referer !== undefined && referer !== '') {
                            window.location.href = referer + '?' + Date.now() + '#proarea'
                        } else {
                            window.location.href = '/'
                        }
                        break
                    case 'unauthorized':
                        const node = document.createElement('div')
                        node.classList.add(['passwd_error'])
                        node.textContent = json.message
                        document
                            .querySelector(`[name="${helper.authenticate.selector}[password]"]`)
                            .insertAdjacentElement('afterend', node)
                        break
                }
            })
            .catch(error => {
                console.log(error)
            })
    })
}

function init() {
    initPasswordForm()
    initPasswordRequestForm()
}
