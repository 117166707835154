// =================
// Homepage Networks
// =================

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const networksList = document.querySelectorAll('.HomepageNetworks-list');

  for (const networksItem of networksList) {
    const sliderContainer = networksItem.querySelector('.swiper-container');

    const networksSlider = new Swiper(sliderContainer, {
      slidesPerView: 1.3,
      spaceBetween: 30,
      watchOverflow: true,
      breakpoints: {
        1100: {
          slidesPerView: 2.5
        },
        800: {
          slidesPerView: 2.3
        },
        600: {
          slidesPerView: 1.8
        }
      },
      navigation: {
        nextEl: '.HomepageNetworks-buttonNext',
        prevEl: '.HomepageNetworks-buttonPrev',
      }
    });
  }
}
